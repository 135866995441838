import {OrdersController} from "../../orders_controller";

export default class extends OrdersController {
  newRent(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._openServicesModalForm(url);
  }

  newMultipleRent(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._openModalForm(url);
  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      super._hideOrUpdateModalContent(window.layout_modal_md, data);
      super._hideOrUpdateModalContent(window.layout_modal_xl, data);

      // this._reloadDriverTab();
      this._reloadDispatchersDriverTabulator();
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._errorNotify(data.notification_title, data.notification_message);

      const error_message = data.error;
      const error_field = document.getElementById('order_drivers_errors')
      if (error_field) error_field.innerHTML = error_message;
    }
  }
}